import { InsightsDashboard } from 'store/insights-studio/types';

export const ExampleDashboards: InsightsDashboard[] = [
  {
    id: 'sampleDash1',
    isExample: true,
    displayName: 'Sample Dashboard',
    description: 'Sample dashboard',
    draftStatus: 'APPROVED',
  },
];
