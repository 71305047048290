export enum AllPermissions {
  ANALYTICS = 'ANALYTICS',

  // Reference Data permissions
  READ_REFERENCE_DATA = 'READ_REFERENCE_DATA',
  WRITE_REFERENCE_DATA = 'WRITE_REFERENCE_DATA',

  // Connector permissions
  WRITE_CONNECTOR = 'WRITE_CONNECTOR',
  READ_CONNECTOR = 'READ_CONNECTOR',
  TEST_CONNECTION = 'TEST_CONNECTION',

  // Profile permissions
  WRITE_PROFILE = 'WRITE_PROFILE',
  READ_PROFILE = 'READ_PROFILE',

  // Tag permissions
  ASSIGN_TAG = 'ASSIGN_TAG',
  REMOVE_TAG = 'REMOVE_TAG',
  READ_TAG = 'READ_TAG',

  // Sync studio permissions
  READ_STUDIO = 'READ_STUDIO',
  WRITE_STUDIO = 'WRITE_STUDIO',

  // Data studio permissions
  READ_DATA_STUDIO = 'READ_DATA_STUDIO',
  WRITE_DATA_STUDIO = 'WRITE_DATA_STUDIO',

  // TxLog Permissions
  VIEW_TRANSACTIONS = 'VIEW_TRANSACTIONS',

  // Role permissions
  ADD_ROLE = 'ADD_ROLE',
  ADD_ROLE_EXP = "hasAuthority('ADD_ROLE')",
  EDIT_ROLE = 'EDIT_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',
  LIST_ROLES = 'LIST_ROLES',
  ADD_PRIV_TO_ROLE = 'ADD_PRIV_TO_ROLE',
  REMOVE_PRIV_FROM_ROLE = 'REMOVE_PRIV_FROM_ROLE',
  ADD_ROLE_TO_USR = 'ADD_ROLE_TO_USR',
  REMOVE_ROLE_FROM_USR = 'REMOVE_ROLE_FROM_USR',

  // Provisioning permissions
  // -- OrgAdminPermissions
  SUB_EDIT = 'SUB_EDIT',
  // -- OrgAdminPermissions
  ADD_INSTANCE = 'ADD_INSTANCE',
  // -- OrgAdminPermissions
  DELETE_INSTANCE = 'DELETE_INSTANCE',
  EDIT_INSTANCE = 'EDIT_INSTANCE',
  INVITE_USER = 'INVITE_USER',
  REINVITE_USER = 'REINVITE_USER',
  LIST_USER = 'LIST_USER',
  LIST_INSTANCE = 'LIST_INSTANCE',
  DELETE_USR = 'DELETE_USR',
  REMOVE_USER = 'REMOVE_USER',
  ACTIVATE_USER = 'ACTIVATE_USER',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  ENABLE_FEATURE = 'ENABLE_FEATURE',
  DISABLE_FEATURE = 'DISABLE_FEATURE',
  GET_FEATURE_STATUS = 'GET_FEATURE_STATUS',
  PROVISION_TRIAL_ORG = 'PROVISION_TRIAL_ORG',

  //SSO Permission
  READ_SSO = 'READ_SSO',
  WRITE_SSO = 'WRITE_SSO',

  //Insights Permission
  ENABLE_INSIGHTS = 'ENABLE_INSIGHTS',
  READ_INSIGHTS = 'READ_INSIGHTS',
  WRITE_INSIGHTS = 'WRITE_INSIGHTS',

  // Insights Dashboard Permission
  CREATE_DASHBOARD = 'CREATE_DASHBOARD',
  PUBLISH_DASHBOARD = 'PUBLISH_DASHBOARD',
  UPDATE_DASHBOARD = 'UPDATE_DASHBOARD',
  DELETE_DASHBOARD = 'DELETE_DASHBOARD',

  // Insights Dashboard Permission
  CREATE_DATACARD = 'CREATE_DATACARD',
  VIEW_DATACARD = 'VIEW_DATACARD',
  PUBLISH_DATACARD = 'PUBLISH_DATACARD',
  UPDATE_DATACARD = 'UPDATE_DATACARD',
  DELETE_DATACARD = 'DELETE_DATACARD',
  PREVIEW_DATACARD = 'PREVIEW_DATACARD',

  // Dataset Permission
  CREATE_DATASET = 'CREATE_DATASET',
  PUBLISH_DATASET = 'PUBLISH_DATASET',
  UPDATE_DATASET = 'UPDATE_DATASET',
  DELETE_DATASET = 'DELETE_DATASET',
  READ_DATASET_FUNCTIONS = 'READ_DATASET_FUNCTIONS',
  EXPORT_DATASET = 'EXPORT_DATASET',
  DOWNLOAD_EXPORTED_DATASET = 'DOWNLOAD_EXPORTED_DATASET',
  VIEW_EXPORT_JOBS = 'VIEW_EXPORT_JOBS',
  CANCEL_EXPORT = 'CANCEL_EXPORT',
  DELETE_EXPORT = 'DELETE_EXPORT',

  SERVICE_CREDENTIAL = 'SERVICE_CREDENTIAL',

  // Quickstart Permissions
  QUICKSTART_SHARE = 'QS_SHARE',

  QUICKSTART_ORG_SHARE = 'QS_ORG_SHARE',

  // SuperAdminPermissions
  QUICKSTART_PUBLISH = 'QS_APPROVE',

  // Custom Actions Permissions
  ACTION_WRITE = 'ACTION_WRITE',
  ACTION_READ = 'ACTION_READ',
  ACTION_SHARE = 'ACTION_SHARE',

  // Reference Data permissions
  READ_FILE_DATA = 'READ_FILE_DATA',
  WRITE_FILE_DATA = 'WRITE_FILE_DATA',
  DELETE_FILE_DATA = 'DELETE_FILE_DATA',

  // -- SuperAdminPermissions
  LIST_ORG = 'LIST_ORG',
  // -- SuperAdminPermissions
  PROVISION_ORG = 'PROVISION_ORG',
  // -- SuperAdminPermissions
  RESET_SUBSCRIPTION = 'RESET_SUBSCRIPTION',
  // -- SuperAdminPermissions
  GHOST_LOGIN = 'GHOST_LOGIN',

  // Added for testing purposes
  DEBUG_DO_NOT_USE = 'DEBUG_DO_NOT_USE',

  // Error notifications
  READ_ERROR_NOTIFICATION_EMAIL = 'READ_ERROR_NOTIFICATION_EMAIL',
  WRITE_ERROR_NOTIFICATION_EMAIL = 'WRITE_ERROR_NOTIFICATION_EMAIL',
  READ_ERROR_NOTIFICATION_WEBHOOK = 'READ_ERROR_NOTIFICATION_WEBHOOK',
  WRITE_ERROR_NOTIFICATION_WEBHOOK = 'WRITE_ERROR_NOTIFICATION_WEBHOOK',

  // Insights sharing
  CREATE_ALLOWED_DOMAINS = 'CREATE_ALLOWED_DOMAINS',
  READ_ALLOWED_DOMAINS = 'READ_ALLOWED_DOMAINS',
  SHARE_DASHBOARD = 'SHARE_DASHBOARD',
  READ_ALL_SHARED_DASHBOARD = 'READ_ALL_SHARED_DASHBOARD',
  READ_SHARED_DASHBOARD_DETAILS = 'READ_SHARED_DASHBOARD_DETAILS',
  DELETE_SHARED_DASHBOARD_DETAILS = 'DELETE_SHARED_DASHBOARD_DETAILS',
  UPDATE_SHARED_DASHBOARD_EXPIRY = 'UPDATE_SHARED_DASHBOARD_EXPIRY',

  APPROVE_CUSTOM_SYNAPSE = 'APPROVE_CUSTOM_SYNAPSE',
}
