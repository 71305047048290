import { Action } from 'redux';
import { ThunkAction as BaseThunkAction } from 'redux-thunk';

import { FilterValue } from 'components/inputs/types';
import { PaginatedApi, PromiseThunkAction } from 'store/types';
import { deleteRequest, get, patch, post, put } from 'utils/AjaxUtil';
import { getErrorMessage } from 'utils/AppUtil';
import DataUrlConstants from 'utils/DataUrlConstants';
import { packageData } from 'utils/ErrorUtils';
import { replaceToken } from 'utils/StringUtil';
import { makeUrl } from 'utils/UrlUtil';

import { RootState } from '../../reducers';
import {
  bookmarkEntityFilterFailed,
  bookmarkEntityFilterFulfilled,
  bookmarkEntityFilterPending,
  createEntityFilterFailed,
  createEntityFilterFulfilled,
  createEntityFilterPending,
  deleteEntityFailed,
  deleteEntityFilterFailed,
  deleteEntityFilterFulfilled,
  deleteEntityFilterPending,
  deleteEntityFulfilled,
  deleteEntityPending,
  deleteRecordDataFailed,
  deleteRecordDataFulfilled,
  deleteRecordDataPending,
  getEntityFiltersFailed,
  getEntityFiltersFulfilled,
  getEntityFiltersPending,
  getEntityRecordDetailFailed,
  getEntityRecordDetailFulfilled,
  getEntityRecordDetailPending,
  getEntityRecordsFailed,
  getEntityRecordsFulfilled,
  getEntityRecordsPending,
  saveEntityFilterFailed,
  saveEntityFilterFulfilled,
  saveEntityFilterPending,
  updateRecordDataFailed,
  updateRecordDataFulfilled,
  updateRecordDataPending,
} from './actions';
import {
  DataStudioFiltersResponse,
  DataStudioRecordDetailResponse,
  DataStudioRecordsResponse,
  EntityFilter,
  EntityRecord,
  ResponseError,
} from './types';

type ThunkAction = BaseThunkAction<void, RootState, unknown, Action<string>>;

type Predicate = FilterValue;

interface GetEntityRecordsArgs extends PaginatedApi {
  entityId: SyncariID;
  predicate?: Predicate;
}

export const getEntityRecords = ({
  entityId,
  predicate,
  cursor,
  count = 100,
  direction = 'next',
}: GetEntityRecordsArgs): ThunkAction => (dispatch) => {
  dispatch(getEntityRecordsPending(entityId));

  const preparedPredicate = predicate ? packageData(predicate) : undefined;

  return post<DataStudioRecordsResponse>(
    makeUrl(
      DataUrlConstants.GET_ENTITY_RECORDS_LIST,
      { entityId },
      {
        cursor,
        count,
        direction,
      }
    ),
    preparedPredicate,
    {
      headers: {
        'Content-Type': 'text/plain',
      },
    }
  )
    .then((resp) => {
      dispatch(getEntityRecordsFulfilled(entityId, resp.data));
    })
    .catch((err) => {
      dispatch(getEntityRecordsFailed(entityId, getErrorMessage(err)));
    });
};

interface GetEntityFiltersArgs extends PaginatedApi {
  entityId?: string | null;
  bookmarked?: boolean;
}

export const getEntityFilters = ({
  entityId = null,
  bookmarked,
  cursor,
  count = 100,
  direction = 'next',
}: GetEntityFiltersArgs): ThunkAction => (dispatch) => {
  dispatch(getEntityFiltersPending(entityId, bookmarked));

  return get<DataStudioFiltersResponse>(
    makeUrl(DataUrlConstants.GET_ENTITY_FILTERS_LIST, null, {
      entityId,
      cursor,
      count,
      direction,
      bookmarked,
    })
  )
    .then((resp) => {
      dispatch(getEntityFiltersFulfilled(entityId, resp.data, bookmarked));
    })
    .catch((err) => {
      dispatch(getEntityFiltersFailed(entityId, getErrorMessage(err), bookmarked));
    });
};

export const createEntityFilter = (
  entityId: string,
  criteria: EntityFilter['criteria'],
  name: string,
  description: string,
  tags: string[],
  bookmarked: boolean
): ThunkAction => (dispatch) => {
  const params = {
    syncariEntityId: entityId,
    criteria,
    name,
    description,
    tags,
    bookmarked,
  };

  dispatch(createEntityFilterPending(entityId));

  return post(DataUrlConstants.CREATE_ENTITY_FILTER, params)
    .then((resp) => {
      dispatch(createEntityFilterFulfilled(entityId, resp.data));
    })
    .catch((err) => {
      dispatch(createEntityFilterFailed(entityId, err));
    });
};

export const updateEntityFilter = (
  filterId: string,
  filter: EntityFilter
): PromiseThunkAction<{ success: boolean }> => (dispatch) => {
  dispatch(saveEntityFilterPending(filterId));

  return put(replaceToken(DataUrlConstants.UPDATE_ENTITY_FILTER, { filterId }), filter)
    .then((resp) => {
      dispatch(saveEntityFilterFulfilled(filterId, resp.data));
      return { success: true };
    })
    .catch((err) => {
      dispatch(saveEntityFilterFailed(filterId, err));
      return { success: false };
    });
};

export const deleteEntityFilter = (filterId: string): ThunkAction => (dispatch) => {
  dispatch(deleteEntityFilterPending(filterId));

  return deleteRequest(replaceToken(DataUrlConstants.DELETE_ENTITY_FILTER, { filterId }))
    .then(() => {
      dispatch(deleteEntityFilterFulfilled(filterId));
    })
    .catch((err) => {
      dispatch(deleteEntityFilterFailed(filterId, err));
    });
};

export const deleteEntity = (entityId: string): ThunkAction => (dispatch) => {
  dispatch(deleteEntityPending(entityId));

  return deleteRequest(replaceToken(DataUrlConstants.DELETE_ENTITY, { entityId }))
    .then(() => {
      dispatch(deleteEntityFulfilled(entityId));
    })
    .catch((err) => {
      dispatch(deleteEntityFailed(entityId, getErrorMessage(err)));
    });
};

export const bookmarkEntityFilter = (filterId: string, bookmarked: boolean): ThunkAction => (dispatch) => {
  dispatch(bookmarkEntityFilterPending(filterId));

  return patch(replaceToken(DataUrlConstants.BOOKMARK_ENTITY_FILTER, { filterId }), bookmarked, {
    headers: { 'content-type': 'application/json' },
  })
    .then(() => {
      dispatch(bookmarkEntityFilterFulfilled(filterId, bookmarked));
    })
    .catch((err) => {
      dispatch(bookmarkEntityFilterFailed(filterId, getErrorMessage(err)));
    });
};

export type UpdateRecordDataResponse = {
  errors: Record<string, string>;
  record: EntityRecord;
};

export const updateRecordData = (
  entityId: string,
  recordId: string,
  recordData: Record<string, any>
): PromiseThunkAction<UpdateRecordDataResponse | void> => (dispatch) => {
  dispatch(updateRecordDataPending(entityId, recordId));

  return put<UpdateRecordDataResponse>(
    replaceToken(DataUrlConstants.UPDATE_RECORD_DATA, { entityId, recordId }),
    recordData
  )
    .then((resp) => {
      if (resp.data.errors && Object.keys(resp.data.errors).length) {
        dispatch(updateRecordDataFailed(entityId, recordId, resp.data.errors));
      } else {
        dispatch(updateRecordDataFulfilled(entityId, recordId, resp.data.record));
      }

      return resp.data;
    })
    .catch((err) => {
      dispatch(updateRecordDataFailed(entityId, recordId, undefined, getErrorMessage(err)));
    });
};

type DeleteRecordSuccess = { success: true };
type DeleteRecordFailure = { success: false; message: string };

export const deleteRecordData = (
  entityId: string,
  recordId: string,
  deleteInEndSystems: boolean
): PromiseThunkAction<DeleteRecordSuccess | DeleteRecordFailure | void> => async (dispatch) => {
  dispatch(deleteRecordDataPending(entityId, recordId));

  try {
    await deleteRequest(makeUrl(DataUrlConstants.DELETE_RECORD_DATA, { entityId, recordId }, { deleteInEndSystems }));
    dispatch(deleteRecordDataFulfilled(entityId, recordId, deleteInEndSystems));
    return { success: true };
  } catch (err) {
    dispatch(deleteRecordDataFailed(entityId, recordId, getErrorMessage(err) as ResponseError));
    return { success: false, message: (err as Error).message };
  }
};

export type GetRecordDetailParams = {
  entityId: string;
  recordId: string;
};

export const getRecordDetail = ({
  entityId,
  recordId,
}: GetRecordDetailParams): PromiseThunkAction<DataStudioRecordDetailResponse | void> => (dispatch) => {
  dispatch(getEntityRecordDetailPending(entityId, recordId));

  return get<DataStudioRecordDetailResponse>(makeUrl(DataUrlConstants.GET_RECORD_DATA, { entityId, recordId }))
    .then((resp) => {
      dispatch(getEntityRecordDetailFulfilled(entityId, recordId, resp.data));

      return resp.data;
    })
    .catch((err) => {
      dispatch(getEntityRecordDetailFailed(entityId, recordId, getErrorMessage(err)));
      return err;
    });
};
