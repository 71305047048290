// Constant string for the List of an EntityTag in RTK-Q state
export const LIST_ID = 'LIST';

export const HALF_SECOND = 500;
export const ONE_SECOND = 1000;
export const TEN_SECONDS = 10 * ONE_SECOND;
export const THIRTY_SECONDS = 30 * ONE_SECOND;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const THREE_MINUTES = 3 * ONE_MINUTE;
export const FIVE_MINUTES = 5 * ONE_MINUTE;
export const TEN_MINUTES = 10 * ONE_MINUTE;
